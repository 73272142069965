import React from "react"
import { graphql } from "gatsby"
// import styled from "styled-components"

import { useContentfulSection, useMeta } from "utils"

import { Layout, Seo } from "components/common"

import { PrimaryNav, MobileNav } from "src/components/navigation"

import { KnowledgeHero } from "src/sections/knowledge"

const KnowledgePage = ({ data: { knowledge } }) => {
  const meta = useMeta(knowledge)
  const { knowledge_hero } = useContentfulSection(knowledge.sections)

  return (
    <Layout>
      <Seo {...meta} />
      <MobileNav />
      <PrimaryNav />

      <KnowledgeHero {...knowledge_hero} />
    </Layout>
  )
}

export const query = graphql`
  {
    knowledge: contentfulPage(pageId: { eq: "knowledge" }) {
      ...Page
    }
  }
`

export default KnowledgePage
