import React from "react"
import styled from "styled-components"
import map from "lodash/map"
import { VSection, AppCard } from "components/common"

import { device, matrixBackgroundStyle } from "utils"

const KnowledgeHero = ({ title, subtitle, valueProps }) => {
  const renderGrid = () => (
    <ul className="kb-grid">
      {map(valueProps, (vp) => (
        <AppCard
          key={vp.id}
          app={vp.vpId.replace("knowledge_hero_", "")}
          vp={vp}
        />
      ))}
    </ul>
  )

  return (
    <StyledKnowledgeHero>
      <VSection
        title={title}
        titleAs="h1"
        subtitle={subtitle}
        subtitleAs="pSmall"
        grid={renderGrid}
        critical
      />
    </StyledKnowledgeHero>
  )
}

const StyledKnowledgeHero = styled.div`
  /* position: relative; */
  --section-mobile-padding-top: var(--sp-mobile-nav);
  --section-padding-top: var(--sp-nav);
  --section-mobile-padding-bottom: 0;

  .v_tgContainer {
    --tg-text-align: center;
    --tg-max-width: 593px;
    --section-align: center;
  }

  .v_gridContainer {
    --section-order: 3;
    --section-mobile-order: 3;
    --section-align: center;

    position: relative;
    padding-top: 64px;
    padding-bottom: 64px;

    @media ${device.laptop} {
      padding-bottom: 0;
      --tg-max-width: 205px;
    }

    .kb-grid {
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: column;
      grid-gap: var(--sp-80);
      margin: 0 auto;
      width: 100%;
      /* max-width: 1000px; */

      @media ${device.laptop} {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        grid-gap: var(--sp-80) var(--sp-32);
      }
    }
  } // end of grid container

  .v_backgroundImage {
    ${matrixBackgroundStyle};
  }
`

export default KnowledgeHero
